import React from 'react'
import Layout from "../components/layout"

import cssPoliticas from '../styles/proveedores.module.scss';

const Proveedores = () => {
    return (
        <Layout>
            <div className={cssPoliticas.raiz}>
                <h1 className={cssPoliticas.titulo}>Política de Sistema de Gestión Integral</h1>
                <p className={cssPoliticas.parrafo}>
                Es compromiso de <em>INNOVA M&D S.A. DE C.V.</em> ofrecer <em>servicios de asesoría, consultoría y estudios en áreas especializadas relacionadas a la producción y perforación de pozos en la industria petrolera</em> en forma oportuna y confiable para cumplir con los requisitos de nuestros clientes y garantizar un nivel de satisfacción constante, basados en un Sistema de Gestión Integral bajo los siguientes compromisos:
                </p>
                <ul className={cssPoliticas.lista}>
                    <li>Cumplir con los requisitos legales aplicables en materia de calidad, medio ambiente, seguridad y salud en el trabajo.</li>
                    <li>Cuidar la salud y la seguridad de nuestros trabajadores, proporcionándoles ambientes seguros, previniendo los accidentes, eliminando los peligros y reduciendo los riesgos, motivando la consulta y participación continua de nuestros colaboradores en todo momento.</li>
                    <li>Proteger al medio ambiente evitando cualquier tipo de contaminación administrando los aspectos e impactos ambientales, buscando el beneficio de las comunidades donde trabajamos y vivimos.</li>
                    <li>Estar a la vanguardia en la gestión de continuidad de negocio.</li>
                </ul>
                <p className={cssPoliticas.parrafo}>Estamos comprometidos a elevar nuestro desempeño en materia de calidad, medio ambiente, seguridad y salud de nuestros colaboradores, a través de la mejora continua del Sistema de Gestión Integral.</p>
            </div>
        </Layout>
    )
}

export default Proveedores;
